.ant-layout-content .center-div {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -100px;

    text-align: center;
}

.hole-instructions {
    margin-bottom: 24px;
}

.hose-card {
    height: 99px;

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.23);
    border-radius: 3px;

    margin-bottom: 24px;
}

.hose-card .ant-card-body {
    padding-top: 0px;
    padding-bottom: 0px;
}

.container-minus-button {
    height: 99px;
    position: relative;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.add-hose-button {
    width: 100%; 
    height: 99px;
    margin-bottom: 24px;
}

.dispensary-table {
    background: #DDF7FF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
}

.dispensary-id {
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-size: 20px;
    color: #595959;
}

a {
    color: #3E92DF;
}

.ant-switch-checked {
    background: #52D094;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: #1D98CD;
    border-color: #1D98CD;
}

.label-inline>label {
    position: relative;
    color: rgb(51, 170, 221);
    font-size: 15px;
    display: inline;
    max-width: 100%;
}