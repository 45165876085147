.login-vh {
    height: 100vh;
}

.login-left-side {
    text-align: center;
}

.login-left-side img {
    vertical-align: middle;
}

.powered-by {
    text-align: center;

}

.powered-by img {
    vertical-align: middle;
      
}

.login-form {
    max-width: 300px;
}

.login-form-button {
    width: 100%;
}
#loginForm_password{
    -webkit-text-security: disc;         
}
#txtUserName{
    -webkit-text-security:disc;    
}
#temporaryPasswordChange_newPassword{
    -webkit-text-security: disc;         
}
#temporaryPasswordChange_confirmPassword{
    -webkit-text-security: disc;         
}