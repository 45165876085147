.actions {
    color: #3E92DF
}

.customized-default,
.customized-default:hover,
.customized-default:focus {
    width: 170px;
    height: 40px;

    border: 1px solid #47B4E3;
    box-sizing: border-box;
    border-radius: 5px;
    color: #47B4E3;
}

.ant-popover-buttons .ant-btn-primary,
.ant-popover-buttons .ant-btn-primary:hover,
.ant-popover-buttons .ant-btn-primary:focus {
    background: #1D98CD;
    color: #FFFFFF;
}

.ant-btn-sm,
.ant-btn-sm:hover,
.ant-btn-sm:hover:focus {
    border: 1px solid #47B4E3;
    color: #47B4E3;
}