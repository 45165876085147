.PDDSelectorBox {
    width: 140px;
    height: auto;
    float: left;
    transition: .5s linear;
    position: relative;
    display: block;
    overflow: hidden;
    padding: 5px;
    text-align: center;
    margin: 0 5px;
    background: transparent;
    text-transform: uppercase;
    border-radius: 5px;
  }
  
  .PDDSelectorBox:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 4px;
    width: 100%;
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
    box-sizing: border-box;
    transform: translateX(100%);
  }
  
  .PDDSelectorBox:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    transform: translateX(-100%);
  }
  
  .PDDSelectorBox:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .PDDSelectorBox:hover:before {
    border-color: #167986;
    height: 100%;
    transform: translateX(0);
    transition: .3s transform linear, .3s height linear .3s;
  }
  
  .PDDSelectorBox:hover:after {
    border-color: #167986;
    height: 100%;
    transform: translateX(0);
    transition: .3s transform linear, .3s height linear .5s;
  }