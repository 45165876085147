.ant-form-item-label label {
    width: 274px;
    height: 21px;

    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 15px;

    color: #1B95CA;
}

.instructions {
    width: 507px;
    height: 21px;

    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    font-size: 15px;

    color: #757575;
}

.larger-checkbox {
    transform: scale(1.8);
}

.ant-input {
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 3px; 
}

.ant-input:hover {
    border: 1px solid #31B4ED;
}

.ant-input:focus {
    border: 1px solid #31B4ED;
}

.customized-primary,
.customized-primary:hover,
.customized-primary:focus
 {
    height: 40px;

    background: #1D98CD;
    border-color: #1D98CD;
    border-radius: 5px;
}

.customized-primary:disabled {
    background: #1D98CD;
    opacity: 0.5;
    color: #FFFFFF;
}

.addres-container {
    border-bottom: 1px solid #DDDDDD;
}

.fiscal-address {
    width: 274px;
    height: 21px;

    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 15px;

    color: #606676;
}

.ant-modal-title {
    height: 24px;

    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;

    color: #1B95CA;
}

.back-button,
.back-button:hover,
.back-button:focus {
    width: 150px;
    height: 32px;

    border: 1px solid #1D98CD;
    box-sizing: border-box;
    border-radius: 5px;

    color: #1B95CA;
}

.submit-button,
.submit-button:hover,
.submit-button:focus {
    width: 150px;
    height: 32px;

    background: #1D98CD;
    border: 1px solid #1D98CD;
    box-sizing: border-box;
    border-radius: 5px;

    color: #FFFFFF;
}

.pin1 {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -10px; 
    margin-top: -20px;
    border-radius: 50% 50% 50% 0;
    border: 4px solid rgb(255, 0, 0);
    width: 24px;
    height: 24px;
    transform: rotate(-45deg);
  }
  
  .pin1::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: rgb(255, 0, 0);
  }

  #components-back-top-demo-custom .ant-back-top {
    bottom: 100px;
  }
  #components-back-top-demo-custom .ant-back-top-inner {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #1088e9;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
