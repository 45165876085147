.day-summary {
    background: #FFFFFF;
    border: 1px solid #E7E7E8;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
    border-radius: 3px;

    padding: 24px;
}

.general-table {
    background: #FFFFFF;
    border: 1px solid #E7E7E8;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.general-table thead th {
    background: #EDF6FF;
    font-weight: bold;
}

.nested-table thead th {
    background: #FAFAFA;
}

td.nested-table-transparent, th.nested-table-transparent {
    background: #FAFAFA;
    color: transparent;
}

.editable-cell {
    position: relative;
} 
  
.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}
  
.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

.empty:hover {
    border: 1px solid #be0e0e;
    border-radius: 4px;
    padding: 12px 12px;
    cursor: pointer;
}

.container-minus-button {
    height: 99px;
    position: relative;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}